<template>
  <div class="table-limit">
    <div id="table-limit-area" class="table-limit-area">
      <table id="table-limit-area-table" class="table-content">
        <thead>
          <tr>
            <td class="tt">
              {{ benchmark ? 'Pilot' : 'Limit' }}
            </td>
            <td v-for="(dt, iDt) in categories" :key="'head-tbl-limit-' + iDt" :class="[getToday(iDt)]">
              {{ $DateMonthReformat(dt) }}
            </td>
          </tr>
        </thead>
        <tbody v-if="!benchmark">
          <tr>
            <td>
              &nbsp;
            </td>
            <td v-for="(fl, iFl) in series[0].data" :key="'limit-fl-' + iFl" :class="[getToday(iFl), getLimit(fl)]">
              {{ fl }}
            </td>
          </tr>
          <tr>
            <td>
              &nbsp;
            </td>
            <td v-for="(fl, iFl) in series[1].data" :key="'limit-fl-' + iFl" :class="[getToday(iFl), getLimit(fl)]">
              {{ iFl >= todayIndex ? $getSumEstimate(series[0].data[iFl], iFl, series[1].data) : '' }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(s, iS) in series" :key="'series-' + iS">
            <td>
              &nbsp;
            </td>
            <td v-for="(fl, iFl) in s.source" :key="'limit-fl-' + iFl" :class="[getToday(iFl), getLimit(fl)]">
              {{ fl.data }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <table v-if="benchmark" class="table-caption">
      <thead>
        <tr v-for="(s, iS) in series" :key="'series-head-' + iS">
          <td class="t">
            {{ s.name }}
          </td>
        </tr>
      </thead>
    </table>
    <table v-else class="table-caption">
      <thead>
        <tr>
          <td class="t">
            {{ limit === 7 ? '40h' : limit === 30 ? '100h' : limit === 90 ? '300h' : '1050h' }}
          </td>
        </tr>
        <tr>
          <td class="t">
            +Est
          </td>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    benchmark: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 7
    },
    categories: {
      type: Array,
      default: () => []
    },
    series: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    todayIndex: 0
  }),
  mounted () {
    this.todayIndex = Math.round(this.categories.length / 2)
    const el = document.getElementById('table-limit-area')
    const tbl = document.getElementById('table-limit-area-table')
    if (el && tbl) {
      const offsetWidth = tbl.offsetWidth
      el.scrollLeft = (((offsetWidth / 2) - (el.offsetWidth / 2))) + 11
    }
  },
  methods: {
    getToday (index) {
      return this.todayIndex === index ? 'today' : ''
    },
    getLimit (fl) {
      // const limit1day = this.$store.state.me ? parseInt(this.$store.state.me.single_crew) ? 6 : 9 : 9
      // const limit7day = this.$store.state.me ? parseInt(this.$store.state.me.aircraft_weight) > 5700 ? 35 : 40 : 40
      // let percent = 0
      return fl * 100 / (this.limit === 7 ? 40 : this.limit === 30 ? 100 : this.limit === 90 ? 300 : this.limit === 365 ? 1050 : 0)
    }
  }
}
</script>

<style lang="scss">
.table-limit {
  position: relative;
  width: 100%;
  padding: 15px;
  min-height: 92px;
  .table-limit-area {
    position: relative;
    width: 100%;
    overflow-x: auto;
    // scroll-behavior: smooth;
  }
  table {
    min-width: 100%;
    position: relative;
    font-size: 11px;
    &.table-caption {
      position: absolute;
      top: 53px;
      left: 16px;
      min-width: 70px;
      @media only screen and (max-width: 768px) {
        display: block;
        top: 53px;
        min-width: auto;
      }
    }
    tr {
      td {
        text-align: center;
        border: solid 1px rgba(0, 0, 0, .05);
        padding: 1px 4px;
        border-radius: 4px;
        color: #555;
        &.t {
          background: #ffdbab;
          min-width: 39px;
        }
        &.today {
          background: #1b0b75;
          color: #fff;
        }
        &.alert {
          background: #CC0000;
          color: #fff;
        }
      }
    }
    thead {
      tr {
        td {
          font-weight: 600;
          background: rgba(0, 0, 0, .05);
          color: #000;
          height: 36px;
          &.tt {
            min-width: 66px;
            @media only screen and (max-width: 768px) {
              min-width: 39px;
            }
          }
          &.t {
            background: #face94;
            height: auto;
          }
        }
      }
    }
  }
}
</style>
